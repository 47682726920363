document.querySelectorAll('[data-megamenu-toggle]').forEach(function (toggle) {
  // delays in milliseconds
  let showDelay = 300;
  let hideDelay = 300;
  // holding variables for timers
  let menuEnterTimer, menuLeaveTimer

  const megaMenuId = toggle.getAttribute('data-megamenu-toggle')
  const megaMenu = document.querySelector(`[data-megamenu="${megaMenuId}"]`)

  function openMegaMenu() {
    // clear the opposite timer
    clearTimeout(menuLeaveTimer)
    // add active class after a delay
    menuEnterTimer = setTimeout(function () {
      megaMenu.classList.add('is-open')
    }, showDelay)
  }

  function closeMegaMenu() {
    // clear the opposite timer
    clearTimeout(menuEnterTimer)
    // remove active class after a delay
    menuLeaveTimer = setTimeout(function () {
      megaMenu.classList.remove('is-open')
    }, hideDelay)
  }

  // toggle.addEventListener('mouseover', openMegaMenu)
  // toggle.addEventListener('focus', openMegaMenu)
  // toggle.addEventListener('mouseleave', closeMegaMenu)
  // megaMenu.addEventListener('mouseover', openMegaMenu)
  // toggle.addEventListener('blur', closeMegaMenu)
  // megaMenu.addEventListener('mouseleave', closeMegaMenu)
})
