import Vue from 'vue'
import axios from 'axios'
import _ from 'lodash'
import { configureApi, apiToken, apiUrl, getQueryParam, executeQuery } from './searchCommon';

import CategorySearchMeta from '../../vue/CategorySearchMeta'
import CategorySearchList from '../../vue/CategorySearchList'
import Pagination from '../../vue/Pagination'
import CategoryFilter from '../../vue/CategoryFilter'

// Site search application
function getcategorySearchLandingData() {
  return {
    sectionType: '',
    searchApi: axios.create(configureApi(apiUrl, apiToken)),
    searchQuery: '',
    searchCategory: {},
    searchResults: [],
    searchSorting: 'postdate desc',
    pagination: {
      totalCount: 0,
      pages: [],
      hasNext: false,
      hasPrev: false,
      nextPageUrl: '',
      prevPageUrl: '',
      elementsPerPage: 12,
      currentPage: 1
    },
    isLoading: true,
    isReady: false
  };
}

let filterChangeTimer;

let searchApp = function () {

  // What to search for
  const searchSections = JSON.parse(document.getElementById('searchApp').dataset.section);
  const searchEntries = JSON.parse(document.getElementById('searchApp').dataset.entries);
  const searchTransform = JSON.parse(document.getElementById('searchApp').dataset.transform);

  let searchQueries = ''
  _.each(searchEntries, (entryType) => {
    searchQueries = searchQueries +
      `
      ... on ${entryType} {
        id
        title
        url
        ${entryType === 'articles_article_Entry' || entryType === 'articles_podcast_Entry' || entryType === 'articles_video_Entry' ? `
          image {
            url @transform (width: 600)
          }
        ` : `
          image {
            url @transform (width: 300)
          }
        `}
        postDate
        ${entryType === 'events_event_Entry' ? `
          startDate
          location
          ingress
          places {
            title
          }
          speaker {
            title
          }
          enrollmentUrl
        ` : ''}
        ${entryType !== 'events_event_Entry' && entryType !== 'pages_page_Entry' ? `
          categories {
            title
          }
        ` : ''}
        ${searchTransform.join('\n')}
      }
      `
  });

  // Define the fragment separately
  const searchableFieldsFragment = `
  fragment searchableFields on EntryInterface {
      ${searchQueries}
  }`;

  // The query to search for entries in Craft
  const searchQuery = `
  ${searchableFieldsFragment}

query searchQuery($site: [String], $sections: [String], $startDate: [QueryArgument], $needle: String, $limit: Int, $offset: Int, $orderBy: String, $idNot: [QueryArgument]) {
    entries(site: $site, limit: $limit, offset: $offset, orderBy: $orderBy, search: $needle, section: $sections, startDate: $startDate, id: $idNot) {
        ...searchableFields
    }
    entryCount(limit: $limit, search: $needle, offset: $offset)
}`;

  new Vue({
    el: document.getElementById('searchApp'),
    delimiters: ['<%', '%>'],
    // Here we can register any values or collections that hold data
    data: getcategorySearchLandingData(),
    components: {
      CategorySearchList,
      CategorySearchMeta,
      Pagination,
      CategoryFilter,
    },
    beforeCreate: function () {
    },
    created: function () {
      // this.selected.location = !!$eventLanding.data('location') && $eventLanding.data('location');

      const searchParam = getQueryParam('q');
      const categoryParam = getQueryParam('cat');
      const categoryIdParam = getQueryParam('cat-id');
      const pageParam = getQueryParam('page');

      if (!!searchParam) {
        this.searchQuery = searchParam;
      }
      if (!!categoryParam) {
        // this.searchCategory.slug = categoryParam;
      }
      if (!!categoryIdParam) {
        this.searchCategory.id = parseInt(categoryIdParam);
      }
      if (!!pageParam) {
        this.pagination.currentPage = parseInt(pageParam);
      }
      this.sectionType = searchSections;
    },
    mounted: function () {
      this.performSearch();
    },
    updated: function () {
    },
    destroyed: function destroyed() {
    },
    watch: {
      'pagination.totalPages': function (val) {
        this.updatePagination();
      },
      'pagination.currentPage': function (val) {
        this.scrollup();
        this.pagination.offset = (this.pagination.currentPage - 1) * this.pagination.elementsPerPage;
        this.performSearch();
      },
      searchQuery: function (val, oldVal) {
        if (!!oldVal && val !== oldVal) {
          this.pagination = _.get(getcategorySearchLandingData(), 'pagination');
        }
        this.performSearch();
      },
      searchCategory: {
        handler: function (val, oldVal) {
          if (!!oldVal && val.id !== oldVal.id) {
            this.pagination = _.get(getcategorySearchLandingData(), 'pagination');
          }
          this.performSearch();
        },
        deep: true
      },
      searchSorting: function () {
        this.scrollup();
        this.performSearch();
      },
    },
    filters: {
    },
    computed: {
    },
    methods: {
      performSearch() {
        let self = this;

        self.isLoading = true;
        self.setHistory();
        let searchTitle = !!self.searchQuery ? `title:*${self.searchQuery}*` : '';
        const searchCategoryString = _.get(self.searchCategory, 'title');
        const searchCategory = !!searchCategoryString && `categories:"${searchCategoryString}" OR categories::"${searchCategoryString}"`;
        const placesCategory = self.sectionType[0] === 'events' && !!searchCategoryString ? `places:"${searchCategoryString}" OR places::"${searchCategoryString}"` : '';
        const speakerCategory = self.sectionType[0] === 'events' && !!searchCategoryString ? `speaker:"${searchCategoryString}" OR speaker::"${searchCategoryString}"` : '';
        const searchTag = !!self.searchTag && `tags:${self.searchQuery} OR tags::${self.searchQuery}`;

        const searchString = _.compact([searchCategory, searchTag, placesCategory, speakerCategory]).join(' OR ');

        // Set the variables we will pass in to our query
        const variables = {
          sections: searchSections,
          needle: _.compact([searchTitle, searchString]).join(' '),
          limit: self.pagination.elementsPerPage || 24,
          offset: (self.pagination.currentPage - 1) * self.pagination.elementsPerPage,
          orderBy: self.searchSorting,
        };
        // Execute the query
        clearTimeout(filterChangeTimer);

        filterChangeTimer = setTimeout(function () {
          executeQuery(self.searchApi, searchQuery, variables, (data) => {
            const dataPath = data.data;
            if (dataPath.entries) {
              self.searchResults = dataPath.entries;
            } else {
              console.error("Error processing GraphQL response:", data);
            }
            self.searchResults = dataPath.entries;
            self.pagination.totalCount = dataPath.entryCount;
            self.pagination.totalPages = Math.ceil(Number(dataPath.entryCount) / (self.pagination.elementsPerPage || 24));;

            if (!self.isReady) {
              self.isReady = true;
            }
            self.isLoading = false;
          });
        }, 500);
      },
      setHistory: function () {
        let self = this;
        let paramString = '';
        if (!!self.searchQuery) {
          paramString += '?q=' + self.searchQuery;
        }
        if (!!_.get(self.searchCategory, 'slug')) {
          paramString += !!paramString ? ('&cat=' + self.searchCategory.slug) : (paramString += '?cat=' + self.searchCategory.slug);
          paramString += !!paramString ? ('&cat-id=' + self.searchCategory.id) : (paramString += '?cat-id=' + self.searchCategory.id);
        }
        if (!!self.pagination.currentPage) {
          paramString += !!paramString ? ('&page=' + self.pagination.currentPage) : (paramString += '?page=' + self.pagination.currentPage);
        }
        if (window.history && window.history.replaceState) {
          let pageUrl =
            location.protocol + '//' + location.host + location.pathname;
          let url = pageUrl + paramString;
          history.replaceState(null, null, url);
        }
      },
      scrollup: function () {
        let top = 0;

        setTimeout(function () {
          window.scrollTo(top, 0);
        }, 100);
        return false;
      },
    },
  });
};

!!document.getElementById('searchApp') && searchApp();
