var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("transition", { attrs: { name: "fadeIn", mode: "in-out" } }, [
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.isLoading || !_vm.isReady,
            expression: "isLoading || !isReady",
          },
        ],
        staticClass: "cardLoader mb-9 md:mb-6 px-3 w-full md:w-1/2",
        class: _vm.sectionType[0] === "articles" ? "xl:w-1/3" : "xl:w-1/4",
        attrs: { "leave-stagger": "500" },
      },
      [
        _c("div", { staticClass: "flex flex-col h-full" }, [
          _c(
            "div",
            {
              staticClass:
                "mb-4 block relative object-fit-fallback aspect-ratio-box aspect-ratio-box--8-5",
            },
            [
              _c("div", {
                staticClass:
                  "cardLoader__image absolute pin-t pin-l w-full h-full block object-cover",
              }),
            ]
          ),
          _vm._v(" "),
          _c("span", {
            staticClass:
              "line-large heading-3 mb-none text-grey-dark hover:text-grey",
          }),
          _vm._v(" "),
          _c("div", { staticClass: "line-medium mt-2 flex items-center" }),
        ]),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }