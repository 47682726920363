<template>
  <transition name="fadeIn" mode="in-out">
    <div class="cardLoader mb-9 pb-9 xxl:pb-0 flex flex-wrap flex-col xxl:flex-row border-b border-1 border-solid border-grey-light" v-show="isLoading || !isReady" leave-stagger="500">
      <div class="w-full xxl:w-1/3">
        <div class="flex flex-col lg:flex-row -mx-3 mb-9">
          <div class="flex flex-col lg:flex-row lg:items-center w-full px-3">
            <div class="mb-4-5 lg:mb-0 md:mr-10">
              <div class="flex flex-col items-center justify-center h-24 w-24 block rounded-full bg-blue-dark">
                <span class="line-micro line-blue-dark mb-1 leading-none"></span>
                <span class="line-micro line-blue-dark leading-none tracking-wide uppercase"></span>
              </div>
            </div>
            <div class="w-full xxl:w-3/4">
              <span class="line-medium heading-3 pr-20"></span>
              <span class="line-small heading-3 pr-20"></span>
              <div class="flex mt-6">
                <div class="flex items-center pr-3">
                  <span class="pr-1 text-blue">
                    <svg class="fill-current block" width="12px" height="12px" viewBox="0 0 768 768">
                      <path d="M549.373 83.24c44.826 44.826 67.24 99.95 67.24 165.373 0 79.961-42.403 192.632-127.21 338.014-39.98 69.057-75.115 124.18-105.403 165.373-30.288-41.192-65.422-96.316-105.403-165.373-84.807-145.384-127.21-258.054-127.21-338.014 0-65.423 22.413-120.547 67.24-165.373s99.95-67.24 165.373-67.24c65.423 0 120.547 22.413 165.373 67.24zM322.212 310.4c16.961 15.75 37.557 23.624 61.787 23.624s44.524-8.178 60.879-24.533c16.356-16.356 24.533-36.648 24.533-60.879s-8.178-44.524-24.533-60.879c-16.356-16.356-36.648-24.533-60.879-24.533s-44.524 8.178-60.879 24.533c-16.356 16.356-24.533 36.648-24.533 60.879s7.875 44.826 23.624 61.787z"></path>
                    </svg>
                  </span>
                  <span class="line-micro pr-3"></span>
                </div>
                <div class="flex items-center">
                  <span class="pr-1 text-blue">
                    <svg class="stroke-current block" width="12px" height="12px" viewBox="0 0 24 24" fill="none" stroke-width="2" stroke-linecap="square" stroke-linejoin="arcs">
                      <circle cx="12" cy="12" r="10"></circle>
                      <polyline points="12 6 12 12 16 14"></polyline>
                    </svg>
                  </span>
                  <span class="line-micro"></span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="flex flex-no-wrap items-center w-full xxl:w-1/2 pr-8 lg:pr-32">
        <div class="w-full mb-9">
          <p class="line-large"></p>
          <p class="line-medium mb-8"></p>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import Vue from 'vue';

export default {
  name: 'EventCardLoadingIndicator',
  props: ['isLoading', 'isReady']
};
</script>
