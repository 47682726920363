<template>
  <transition name="fadeIn" mode="in-out">
    <li class="mb-4 pb-4 flex flex-wrap flex-col xxl:flex-row border-b border-1 border-solid border-grey-light" v-show="!isLoading">
      <div class="w-full">
        <div class="flex flex-col lg:flex-row -mx-3">
          <div class="flex flex-row lg:items-top w-full px-3">
            <div class="flex flex-col h-full w-2/5 mr-5">
              <template v-if="item.typeId === 4">
                <div class="flex justify-center align-center py-2">
                  <div class="relative flex flex-col items-center justify-center h-24 w-24 block rounded-full bg-blue-dark overflow-hidden">
                    <span class="mb-1 font-sans-bold text-3xl leading-none text-white">{{formatDate(item.startDate, 'date')}}</span>
                    <span class="font-sans-bold text-2xs leading-none tracking-wide text-blue-light uppercase">{{formatDate(item.startDate, 'day')}}</span>
                  </div>
                </div>
              </template>
              <template v-else>
                <div class="block relative object-fit-fallback aspect-ratio-box aspect-ratio-box--8-5">
                  <img
                    class="absolute lazyload w-full h-full block object-cover"
                    :src="!!item.image && !!item.image[0] ? item.image[0].url : '/assets/img/martha_recipe_placeholder.jpg'"
                    alt="">
                </div>
              </template>
            </div>
            <div class="w-full flex flex-col pr-3">
              <a class="" :href="item.url">
                <h2 class="heading-3 text-grey-dark hover:text-grey">{{item.title.length > 117 ? item.title.slice(0, 114) + '...' : item.title}}</h2>
              </a>
              <template v-if="item.typeId === 4">
                <div class="flex mt-auto">
                  <div class="flex items-center">
                    <span class="pr-1 text-blue">
                      <svg class="fill-current block" width="12px" height="12px" viewBox="0 0 768 768">
                        <path d="M549.373 83.24c44.826 44.826 67.24 99.95 67.24 165.373 0 79.961-42.403 192.632-127.21 338.014-39.98 69.057-75.115 124.18-105.403 165.373-30.288-41.192-65.422-96.316-105.403-165.373-84.807-145.384-127.21-258.054-127.21-338.014 0-65.423 22.413-120.547 67.24-165.373s99.95-67.24 165.373-67.24c65.423 0 120.547 22.413 165.373 67.24zM322.212 310.4c16.961 15.75 37.557 23.624 61.787 23.624s44.524-8.178 60.879-24.533c16.356-16.356 24.533-36.648 24.533-60.879s-8.178-44.524-24.533-60.879c-16.356-16.356-36.648-24.533-60.879-24.533s-44.524 8.178-60.879 24.533c-16.356 16.356-24.533 36.648-24.533 60.879s7.875 44.826 23.624 61.787z"></path>
                      </svg>
                    </span>
                    <span class="pr-3 small-text text-grey-dark group-hover:text-grey">{{item.location}}</span>
                  </div>
                  <div class="flex items-center">
                    <span class="pr-1 text-blue">
                      <svg class="stroke-current block" width="12px" height="12px" viewBox="0 0 24 24" fill="none" stroke-width="2" stroke-linecap="square" stroke-linejoin="arcs">
                        <circle cx="12" cy="12" r="10"></circle>
                        <polyline points="12 6 12 12 16 14"></polyline>
                      </svg>
                    </span>
                    <span class="small-text text-grey-dark group-hover:text-grey">{{formatDate(item.startDate, 'time')}}</span>
                  </div>
                </div>
              </template>
              <div v-if="!!item.categories" class="mt-auto">
                <span 
                  v-for="(cat, key) in item.categories.slice(1, 3)"
                  :key="key"
                  class="small-text text-grey-dark inline-block group-hover:text-grey">{{ cat.title }}<span v-if="key < item.categories.slice(1, 3).length - 1" class="inline-block px-1">|</span></span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </li>
  </transition>
</template>

<script>
import Vue from 'vue';
import moment from 'moment';

moment.locale('sv');

export default {
  name: 'EventSearchItem',
  props: {
    item: {
      type: Object
    },
    isLoading: {
      type: Boolean
    }
  },
  methods: {
    formatDate: function(date, format) {
      const momentDate = moment.unix(date);

      switch (format) {
        case 'time':
          return momentDate.format('HH:mm');
        break;
        case 'day':
          return momentDate.format('dddd');
        break;
        case 'date':
          return momentDate.format('DD.MM');
        break;
      }
    }
  }
};
</script>
