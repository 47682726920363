var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "nav",
    {
      staticClass: "content-wrapper mx-auto pt-3",
      attrs: { "aria-label": "Paginering" },
    },
    [
      _vm.pages > 1
        ? _c(
            "ul",
            {
              staticClass:
                "pt-6 list-reset flex items-center justify-center small-text line-height-none",
            },
            [
              _vm.current > 1
                ? _c(
                    "li",
                    {
                      staticClass: "cursor-pointer",
                      attrs: { title: "Föregående sida", tabindex: "0" },
                      on: {
                        click: function ($event) {
                          return _vm.changePage(_vm.current - 1)
                        },
                        keyup: function ($event) {
                          if (
                            !$event.type.indexOf("key") &&
                            _vm._k($event.keyCode, "space", 32, $event.key, [
                              " ",
                              "Spacebar",
                            ])
                          )
                            return null
                          return _vm.changePage(_vm.current - 1, arguments[0])
                        },
                      },
                    },
                    [
                      _c(
                        "span",
                        {
                          staticClass:
                            "transform-left pr-3 inline-block text-blue-accessible-text",
                        },
                        [
                          _c(
                            "svg",
                            {
                              staticClass: "fill-current block",
                              attrs: {
                                width: "16px",
                                height: "16px",
                                viewBox: "0 0 768 768",
                              },
                            },
                            [
                              _c("path", {
                                attrs: {
                                  d: "M236.383 567.534c12.158 12.158 12.158 26.115 0 38.275s-26.115 12.158-38.521-0.258c-120.337-132.524-183.641-202.157-185.038-203.556-4.631-4.631-6.804-11.144-6.804-19.139 0-7.991 2.168-14.506 6.56-18.883l185.525-204.065c4.631-4.631 11.144-6.804 19.138-6.804s14.506 2.168 19.138 6.804c12.158 12.158 12.158 26.115 0.253 38.004l-143.963 159.001h643.369c16.447 0 25.939 9.496 25.939 25.94s-9.496 25.94-25.939 25.94h-643.369l143.709 158.737z",
                                },
                              }),
                            ]
                          ),
                        ]
                      ),
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm._l((_vm.current - 2, _vm.current + 2), function (n) {
                return [
                  n > 0 && n >= _vm.current - 2 && n <= _vm.pages
                    ? _c(
                        "li",
                        {
                          key: n,
                          staticClass: "cursor-pointer",
                          attrs: {
                            "aria-current": n === _vm.current ? "page" : false,
                            tabindex: "0",
                          },
                          on: {
                            click: function ($event) {
                              return _vm.changePage(n)
                            },
                            keyup: function ($event) {
                              if (
                                !$event.type.indexOf("key") &&
                                _vm._k(
                                  $event.keyCode,
                                  "space",
                                  32,
                                  $event.key,
                                  [" ", "Spacebar"]
                                )
                              )
                                return null
                              return _vm.changePage(n)
                            },
                          },
                        },
                        [
                          _c(
                            "span",
                            {
                              staticClass:
                                "px-3 hover:text-blue-accessible-text",
                              class:
                                n === _vm.current
                                  ? "text-blue-accessible-text"
                                  : "text-grey-accessible-text",
                              attrs: {
                                rel:
                                  n > _vm.current
                                    ? "next"
                                    : n < _vm.current
                                    ? "prev"
                                    : false,
                                "aria-label": "sida " + n,
                              },
                            },
                            [_vm._v("\n          " + _vm._s(n) + "\n        ")]
                          ),
                        ]
                      )
                    : _vm._e(),
                ]
              }),
              _vm._v(" "),
              _vm.current < _vm.pages
                ? _c(
                    "li",
                    {
                      staticClass: "cursor-pointer",
                      attrs: { title: "Nästa sida", tabindex: "0" },
                      on: {
                        click: function ($event) {
                          return _vm.changePage(_vm.current + 1)
                        },
                        keyup: function ($event) {
                          if (
                            !$event.type.indexOf("key") &&
                            _vm._k($event.keyCode, "space", 32, $event.key, [
                              " ",
                              "Spacebar",
                            ])
                          )
                            return null
                          return _vm.changePage(_vm.current + 1, arguments[0])
                        },
                      },
                    },
                    [
                      _c(
                        "span",
                        {
                          staticClass:
                            "transform-right pl-3 inline-block text-blue-accessible-text",
                        },
                        [
                          _c(
                            "svg",
                            {
                              staticClass: "fill-current block",
                              attrs: {
                                width: "16px",
                                height: "16px",
                                viewBox: "0 0 768 768",
                              },
                            },
                            [
                              _c("path", {
                                attrs: {
                                  d: "M531.618 200.469c-12.16-12.16-12.16-26.115 0-38.276s26.115-12.16 38.521 0.257c120.337 132.524 183.641 202.156 185.038 203.557 4.631 4.631 6.803 11.144 6.803 19.138s-2.17 14.506-6.56 18.881l-185.526 204.067c-4.631 4.631-11.143 6.803-19.138 6.803s-14.506-2.17-19.138-6.803c-12.16-12.16-12.16-26.115-0.254-38.005l143.963-159.002h-643.369c-16.446 0-25.938-9.496-25.938-25.938s9.496-25.938 25.938-25.938h643.369l-143.709-158.738z",
                                },
                              }),
                            ]
                          ),
                        ]
                      ),
                    ]
                  )
                : _vm._e(),
            ],
            2
          )
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }