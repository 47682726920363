var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass:
        "mb-0 px-3 w-1/2 flex flex-wrap items-center justify-center md:justify-end",
    },
    [
      _c(
        "label",
        {
          staticClass: "pr-3 small-text text-grey-accessible-text",
          attrs: { for: "sorting" },
        },
        [_vm._v("Sortera")]
      ),
      _vm._v(" "),
      _c("div", { staticClass: "relative max-w-xxxs" }, [
        _c(
          "select",
          {
            staticClass:
              "block appearance-none w-full bg-transparent pr-12 focus:outline-none font-sans-bold text-sm font-normal leading-loose tracking-slight text-blue-accessible-text hover:text-blue-light cursor-pointer",
            attrs: { id: "sorting", sorting: _vm.sorting },
            on: {
              "update:sorting": function ($event) {
                _vm.sorting = $event
              },
              change: function ($event) {
                return _vm.$emit("update:sorting", $event.target.value)
              },
            },
          },
          _vm._l(_vm.options, function (option, value) {
            return _c(
              "option",
              {
                key: value,
                domProps: {
                  value: option.value,
                  selected: option.value === _vm.sorting,
                },
              },
              [_vm._v(_vm._s(option.title))]
            )
          }),
          0
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass:
              "pointer-events-none absolute pin-y pin-r flex items-center text-grey-dark",
          },
          [
            _c("span", { staticClass: "h-4 inline-block text-grey-dark" }, [
              _c(
                "svg",
                {
                  staticClass: "stroke-current h-3 w-3",
                  attrs: { viewBox: "0 0 768 768" },
                },
                [
                  _c("path", {
                    attrs: {
                      fill: "none",
                      "stroke-linejoin": "miter",
                      "stroke-linecap": "square",
                      "stroke-miterlimit": "4",
                      "stroke-width": "96",
                      d: "M95.999 240l288.001 288.001 288.001-288.001",
                    },
                  }),
                ]
              ),
            ]),
          ]
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }