// We import our styles here, webpack will do the rest
import styles from '../scss/app.scss'

// babel polyfill to fix for older browsers
import '@babel/polyfill'

// Vue js
import Vue from 'vue'

// lazy sizes for lazy loading images
import Lazysizes from 'lazysizes'
import 'picturefill'

// Our own helpers
import './helpers/objectfitFallback'
import reframe from './helpers/iframe'

// Our own components
import './components/formStyle'
import './components/toggle'
import './components/lockTrigger'
import './components/megamenu'

// For search
import './components/searchCommon'
import './components/categorySearch'
import './components/generalSearch'
import './components/searchPageSearch'

// For hiding sub nav
import './components/headroom'

// Window onload (after all dom and images are loaded completely)
window.onload = function () {
  // responsive iframes
  reframe(document.querySelectorAll('.wysiwyg iframe')) // Pass a live node list

  // Page has loaded, remove loading class
  document.getElementsByTagName('body')[0].classList.remove('loading')

  // Responsive tables
  // wrap tables in wysiwyg in a div for styling
  const tables = document.querySelectorAll('.wysiwyg table')
  if (tables.length) {
    if (!('length' in tables)) tables = [tables]
    for (let i = 0; i < tables.length; i += 1) {
      const table = tables[i]

      const div = document.createElement('div')
      div.className = 'table-container'

      table.parentNode.insertBefore(div, table)
      table.parentNode.removeChild(table)
      div.appendChild(table)
    }
  }

  const siteNavigation = document.querySelector('.site-navigation');
  const navigationToggles = document.querySelectorAll('[menu-toggle]');

  function navToggleFunctionality(event) {
    if (siteNavigation) {
      siteNavigation.classList.toggle('is-open');
      let ariaExpanded = false;
      if (siteNavigation.classList.contains('is-open')) {
        ariaExpanded = "true"
        if (event.target.id && event.target.id === 'open-search') {
          document.getElementById('site-navigation-input').focus();
        }
      }
      for (let index = 0; index < navigationToggles.length; index++) {
        const navigationToggle = navigationToggles[index];
        navigationToggle.setAttribute("aria-expanded", ariaExpanded);
      }
    }
  }

  for (let index = 0; index < navigationToggles.length; index++) {
    const navigationToggle = navigationToggles[index];
    navigationToggle.addEventListener('click', navToggleFunctionality)
  }


  function skipToContent(e) {
    if (e.type === "keydown" && e.key !== ' ') {
      return;
    }

    e.preventDefault();
    const target = document.getElementById('content');
    target.setAttribute('tabindex', '-1');
    target.focus();
  }
  const skipLink = document.getElementById('skip-link');

  ['click', 'ontouchkeydown', 'touchend'].forEach(function (evt) {
    skipLink.addEventListener(evt, skipToContent, false);
  });

  document.addEventListener('keyup', (event) => {
    if ((event.key === 'Escape') && siteNavigation.classList.contains('is-open')) {
      siteNavigation.classList.remove('is-open');
      for (let index = 0; index < navigationToggles.length; index++) {
        const navigationToggle = navigationToggles[index];
        navigationToggle.setAttribute("aria-expanded", 'false');
      }
    }
  })
}