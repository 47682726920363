<template>
  <div class="relative flex flex-wrap flex-col lg:flex-row -mx-1">
    <form v-on:submit.prevent action="" method="post" class="mb-3 lg:mb-0 px-1 w-full lg:w-1/2">
      <div class="text-grey-dark relative bg-white" :class="{'shadow-sm': !isCategoryFilterOpen}">
        <input
          v-bind:value="query"
          v-on:input="$emit('update:query', $event.target.value)"
          class="font-sans font-normal text-base leading-none appearance-none bg-white border-none w-full text-grey-dark h-21 pl-20 pr-12 focus:outline-none"
          :class="{'bg-grey-light opacity-50': isCategoryFilterOpen}"
          style="line-height: 5.25rem;"
          type="text"
          placeholder="Hitta"
          aria-label="Hitta"
        >
        <button 
          class="border-transparent pl-6 flex items-center absolute pin-t pin-l h-21 inline-block" 
          :class="{'text-blue': !isCategoryFilterOpen, 'text-grey-even-lighter': isCategoryFilterOpen}" 
          type="submit" 
          value="Submit"
          aria-labelledby="recipe-search-button"
          @click="performSearch"
        >
          <svg class="stroke-current h-8 w-8" viewBox="0 0 768 768" preserveAspectRatio="none">
            <path fill="none" stroke-linejoin="miter" stroke-linecap="round" stroke-miterlimit="4" stroke-width="46.0800" d="M507.869 516.665l205.21 187.616"></path>
            <path fill="none" stroke-linejoin="miter" stroke-linecap="round" stroke-miterlimit="4" stroke-width="46.0800" d="M507.869 516.665c-103.618 103.618-271.615 103.618-375.233 0s-103.618-271.616 0-375.233c103.618-103.618 271.615-103.618 375.233 0s103.618 271.616 0 375.233z"></path>
          </svg>
          <span id="recipe-search-button" class="sr-only text-blue-accessible">Sök</span>
        </button>
      </div>
    </form>
    <div class="px-1 w-full lg:w-1/2">
      <div 
        @click="toggleCategoryFilter()"
        v-on:keyup.space.self="toggleCategoryFilter($event)"
        v-on:keydown.self="preventScroll"
        class="preventClosingFilterContainer font-sans font-normal text-base leading-none text-white pl-8 pr-6 h-21 block flex justify-between items-center w-full appearance-none bg-blue-dark border-none rounded-none focus:outline-none shadow-sm cursor-pointer"
        tabindex="0"
        :aria-expanded="this.isCategoryFilterOpen ? 'true' : 'false'"
        role="button"
      >
        <template v-if="!!selectedCategory.title">
          <button
            class="leading-none flex btn-rnd btn-blue flex-no-shrink px-3"
            @click="categorySelector(undefined, undefined);"
            aria-labelledby="category-selector-button"
          >
          {{selectedCategory.title}}
          <div
            class="inlin-block ml-1-5"
            style="transform: rotate(45deg)"
          >
            <svg
              class="stroke-current block pointer-events-none"
              width="14px"
              height="14px"
              viewBox="0 0 24 24"
              fill="none"
              stroke-width="3"
              stroke-linecap="square"
              stroke-linejoin="arcs"
              preserveAspectRatio="none"
            >
              <line x1="12" y1="5" x2="12" y2="19"></line>
              <line x1="5" y1="12" x2="19" y2="12"></line>
            </svg>
          </div>
          <span id="category-selector-button" class="sr-only">Välj kategori {{selectedCategory.title}}</span>
        </button>
        </template>
        <template v-else>
          <span class="pointer-events-none transition-opacity" :class="{'opacity-50': isCategoryFilterOpen}">
            Sök per kategori
          </span>
        </template>
        <span class="inline-block text-white pointer-events-none transition-transform" :class="{'opacity-50 transform-upside-down': isCategoryFilterOpen}">
          <svg class="fill-current" width="20px" height="20px" viewBox="0 0 768 768" preserveAspectRatio="none">
            <path d="M355.457 16h57.086v624.466l163.103-161.86 40.775 40.805-232.42 232.588-232.421-232.588 40.775-42.165 163.102 163.219z"></path>
          </svg>
        </span>
      </div>
    </div>
    <div v-show="isCategoryFilterOpen" id="filterContainer" class="absolute max-w-lg bg-blue-dark pt-8 pb-6 px-6 lg:px-12 z-10 pin-r pin-l mx-1" style="top: 100%">
      <template v-for="(categoryGroup, key) in categoryFilters">
        <hr v-if="sectionType[0] !== 'events' && key === categoryFilters.length - 1" :key="'hr-' + key" class="border-t border-solid border-grey-hr mt-4">
        <div
          :key="key"
          class="flex flex-col md:flex-row"
          :class="{
            'mt-w':  key > 0,
            'mt-6': (sectionType[0] !== 'events' && key === categoryFilters.length - 1),
            'mb-0': (sectionType[0] === 'events' && key === categoryFilters.length - 1)
          }"
        >
          <div
            class="text-white text-sm w-2/5 md:w-1/4 mt-4 md:mt-2 flex-no-shrink"
          >
            <span
              class="font-sans-bold"
              @click="!!categoryGroup.groupCategory && categorySelector(categoryGroup.groupCategory, undefined);"
              :class="{
                'cursor-pointer': !!categoryGroup.groupCategory,
                'border-b': !!categoryGroup.groupCategory && selectedCategory.id === categoryGroup.groupCategory.id
              }"
            >{{categoryGroup.groupTitle}}</span>
          </div>
          <div class="w-full md:w-5/6 mt-4 md:mt-0 flex-no-grow" data-toggle-container>
            <span
              v-for="(cat, key) in categoryGroup.categories"
              :key="key"
              :data-toggle-class="key > 7 && 'hidden'"
              :class="{'hidden': key > 7}"
            >
              <button
                class="leading-none btn-rnd btn-blue mb-3 mr-2 inline-flex flex-no-shrink px-3"
                @click="categorySelector(cat, categoryGroup.groupCategory);"
                :id="cat.id"
                :class="{'btn-white': selectedCategory.id === cat.id}"
              >
                {{cat.title}}
                <div
                  v-if="selectedCategory.id === cat.id"
                  class="inlin-block ml-1-5"
                  style="transform: rotate(45deg)"
                >
                  <svg
                    class="stroke-current block pointer-events-none"
                    width="14px"
                    height="14px"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke-width="3"
                    stroke-linecap="square"
                    stroke-linejoin="arcs"
                    preserveAspectRatio="none"
                  >
                    <line x1="12" y1="5" x2="12" y2="19"></line>
                    <line x1="5" y1="12" x2="19" y2="12"></line>
                  </svg>
                </div>
              </button>
            </span>
            <button v-if="categoryGroup.categories.length -1 > 7"
              data-toggle
              class="btn-rnd btn-blue-dark mb-3 mr-2 flex-no-shrink px-3"
            >
              <span data-toggle-text="Visa färre">Visa fler</span>
              <span class="pl-1 h-2 text-white inline-block">
                <span data-toggle-class="hidden">
                  +
                </span>
                <span class="hidden" data-toggle-class="hidden">
                  -
                </span>
              </span>
            </button>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import _ from 'lodash'

export default {
  name: 'CategoryFilter',
  props: {
    'query': {
      type: String,
      default: ''
    },
    'sectionType': {
      type: Array,
      default: []
    },
    'categoryFilters': {
      type: Array,
      default: []
    },
    'selectedCategory': {
      type: Object,
      default: () => ({
        title: {
          type: String
        },
        slug: {
          type: String
        },
        id: {
          type: String
        },
        parentCategory: {
          type: Object,
        }
      })
    }
  },
  created: function() {
    const self = this;
    const catBlocks = _.flatMapDeep(this.categoryFilters);
    const selectedId = this.selectedCategory.id;

    _.each(catBlocks, (catBlock)=> {
      if(!!catBlock.groupCategory && catBlock.groupCategory.id === selectedId) {
        this.selectedCategory = catBlock.groupCategory;
      } else {
        const cat = _.find(catBlock.categories, (c)=>{
            return c.id === selectedId
        });
        if(!!cat) {
          this.selectedCategory.title = cat.title;
          this.selectedCategory.slug = cat.slug;
          this.selectedCategory.id = cat.id;
          this.selectedCategory.parentCategory = catBlock.groupCategory;
        }
      }
    })

  },
  mounted: function() {
    document.addEventListener('click', this.closeCategoryFilter, false);
    this.toggleCat();
  },
  updated: function() {
  },
  destroyed: function() {
    document.removeEventListener('click', this.closeCategoryFilter);
  },
  data() {
    return {
      isCategoryFilterOpen: false,
    };
  },
  computed: {
  },
  methods: {
    closeCategoryFilter: function(event) {
      const isInsideContainer = this.parents(event.target, document.getElementById('filterContainer'));
      if (!(event.target.classList.contains('preventClosingFilterContainer') || isInsideContainer)) {
        this.isCategoryFilterOpen = false;
      }
    },
    parents: function(el, parentSelector) {
      const grandParentSelector = !!parentSelector ? parentSelector.parentElement : null;
      if(!grandParentSelector) {
        return false;
      }

      let hasParents = false;
      let p = el.parentElement;

      if (el === parentSelector) {
        hasParents = true;
      } else {
        while (!!p && p !== grandParentSelector) {
            var o = p;
            if( o === parentSelector ) {
              hasParents = true; // Push that parentSelector you wanted to stop at
            }
            p = o.parentElement;
        }
      }

      return hasParents;
    },
    toggleCategoryFilter: function(event) {
      if (event) {
        event.preventDefault();
      }
      this.isCategoryFilterOpen = !this.isCategoryFilterOpen;
    },
    preventScroll: function(event) {
      if (event.key === ' ') {
        event.preventDefault();
      }
      else if (event.key === 'Enter') {
        event.preventDefault();
        this.toggleCategoryFilter();
      }
    },
    categorySelector: function(cat, groupCat) {
      const catObj = {};
      if(!!cat && !!this.selectedCategory.id && this.selectedCategory.id === cat.id) {
        catObj.title = '';
        catObj.slug = '';
        catObj.id = '';
        catObj.parentCategory = {};
      } else {
        catObj.title = _.get(cat, 'title');
        catObj.slug = _.get(cat, 'slug');
        catObj.id = _.get(cat, 'id');
        // catObj.parentCategory = _.get(cat, 'title');
        catObj.parentCategory = !!groupCat ? groupCat : undefined;
        this.isCategoryFilterOpen = false;
      }
      this.$emit('update:selected-category', catObj);
    },
    toggleCat: function() {
      // assign HTMLCollection with parents of images with objectFit to variable
      const toggleContainer = document.querySelectorAll('[data-toggle-container]')

      // Loop through HTMLCollection
      for (let i = 0; i < toggleContainer.length; i++) {
        const toggle = toggleContainer[i].querySelector('[data-toggle]')

        if (toggle !== null) {
          toggle.onclick = function(e) {
            e.preventDefault();
            const toggleItems = toggleContainer[i].querySelectorAll(
              '[data-toggle-class]',
            )

            for (let j = 0; j < toggleItems.length; j++) {
              const toggleClass = toggleItems[j].getAttribute('data-toggle-class')

              if (toggleItems[j].classList) {
                toggleItems[j].classList.toggle(toggleClass)
              } else {
                var classes = toggleItems[j].className.split(' ')
                var existingIndex = classes.indexOf(toggleClass)

                if (existingIndex >= 0) classes.splice(existingIndex, 1)
                else classes.push(toggleClass)

                toggleItems[j].className = classes.join(' ')
              }
            }

            // if toggle text is found, toggle the text
            if (toggle.querySelector('[data-toggle-text]') !== null) {
              const toggleText = toggle.querySelector('[data-toggle-text]')
              const originalText = toggleText.innerHTML
              const newText = toggleText.getAttribute('data-toggle-text')

              toggleText.innerHTML = newText
              toggleText.setAttribute('data-toggle-text', originalText)
            }
          }
        }
      }
    },
    performSearch: function() {
      this.$emit('search')
    },
  },
}
</script>
