var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "relative flex flex-wrap flex-col lg:flex-row -mx-1" },
    [
      _c(
        "form",
        {
          staticClass: "mb-3 lg:mb-0 px-1 w-full lg:w-1/2",
          attrs: { action: "", method: "post" },
          on: {
            submit: function ($event) {
              $event.preventDefault()
            },
          },
        },
        [
          _c(
            "div",
            {
              staticClass: "text-grey-dark relative bg-white",
              class: { "shadow-sm": !_vm.isCategoryFilterOpen },
            },
            [
              _c("input", {
                staticClass:
                  "font-sans font-normal text-base leading-none appearance-none bg-white border-none w-full text-grey-dark h-21 pl-20 pr-12 focus:outline-none",
                class: { "bg-grey-light opacity-50": _vm.isCategoryFilterOpen },
                staticStyle: { "line-height": "5.25rem" },
                attrs: {
                  type: "text",
                  placeholder: "Hitta",
                  "aria-label": "Hitta",
                },
                domProps: { value: _vm.query },
                on: {
                  input: function ($event) {
                    return _vm.$emit("update:query", $event.target.value)
                  },
                },
              }),
              _vm._v(" "),
              _c(
                "button",
                {
                  staticClass:
                    "border-transparent pl-6 flex items-center absolute pin-t pin-l h-21 inline-block",
                  class: {
                    "text-blue": !_vm.isCategoryFilterOpen,
                    "text-grey-even-lighter": _vm.isCategoryFilterOpen,
                  },
                  attrs: {
                    type: "submit",
                    value: "Submit",
                    "aria-labelledby": "recipe-search-button",
                  },
                  on: { click: _vm.performSearch },
                },
                [
                  _c(
                    "svg",
                    {
                      staticClass: "stroke-current h-8 w-8",
                      attrs: {
                        viewBox: "0 0 768 768",
                        preserveAspectRatio: "none",
                      },
                    },
                    [
                      _c("path", {
                        attrs: {
                          fill: "none",
                          "stroke-linejoin": "miter",
                          "stroke-linecap": "round",
                          "stroke-miterlimit": "4",
                          "stroke-width": "46.0800",
                          d: "M507.869 516.665l205.21 187.616",
                        },
                      }),
                      _vm._v(" "),
                      _c("path", {
                        attrs: {
                          fill: "none",
                          "stroke-linejoin": "miter",
                          "stroke-linecap": "round",
                          "stroke-miterlimit": "4",
                          "stroke-width": "46.0800",
                          d: "M507.869 516.665c-103.618 103.618-271.615 103.618-375.233 0s-103.618-271.616 0-375.233c103.618-103.618 271.615-103.618 375.233 0s103.618 271.616 0 375.233z",
                        },
                      }),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "span",
                    {
                      staticClass: "sr-only text-blue-accessible",
                      attrs: { id: "recipe-search-button" },
                    },
                    [_vm._v("Sök")]
                  ),
                ]
              ),
            ]
          ),
        ]
      ),
      _vm._v(" "),
      _c("div", { staticClass: "px-1 w-full lg:w-1/2" }, [
        _c(
          "div",
          {
            staticClass:
              "preventClosingFilterContainer font-sans font-normal text-base leading-none text-white pl-8 pr-6 h-21 block flex justify-between items-center w-full appearance-none bg-blue-dark border-none rounded-none focus:outline-none shadow-sm cursor-pointer",
            attrs: {
              tabindex: "0",
              "aria-expanded": this.isCategoryFilterOpen ? "true" : "false",
              role: "button",
            },
            on: {
              click: function ($event) {
                return _vm.toggleCategoryFilter()
              },
              keyup: function ($event) {
                if (
                  !$event.type.indexOf("key") &&
                  _vm._k($event.keyCode, "space", 32, $event.key, [
                    " ",
                    "Spacebar",
                  ])
                )
                  return null
                if ($event.target !== $event.currentTarget) return null
                return _vm.toggleCategoryFilter($event)
              },
              keydown: function ($event) {
                if ($event.target !== $event.currentTarget) return null
                return _vm.preventScroll.apply(null, arguments)
              },
            },
          },
          [
            !!_vm.selectedCategory.title
              ? [
                  _c(
                    "button",
                    {
                      staticClass:
                        "leading-none flex btn-rnd btn-blue flex-no-shrink px-3",
                      attrs: { "aria-labelledby": "category-selector-button" },
                      on: {
                        click: function ($event) {
                          return _vm.categorySelector(undefined, undefined)
                        },
                      },
                    },
                    [
                      _vm._v(
                        "\n        " +
                          _vm._s(_vm.selectedCategory.title) +
                          "\n        "
                      ),
                      _c(
                        "div",
                        {
                          staticClass: "inlin-block ml-1-5",
                          staticStyle: { transform: "rotate(45deg)" },
                        },
                        [
                          _c(
                            "svg",
                            {
                              staticClass:
                                "stroke-current block pointer-events-none",
                              attrs: {
                                width: "14px",
                                height: "14px",
                                viewBox: "0 0 24 24",
                                fill: "none",
                                "stroke-width": "3",
                                "stroke-linecap": "square",
                                "stroke-linejoin": "arcs",
                                preserveAspectRatio: "none",
                              },
                            },
                            [
                              _c("line", {
                                attrs: {
                                  x1: "12",
                                  y1: "5",
                                  x2: "12",
                                  y2: "19",
                                },
                              }),
                              _vm._v(" "),
                              _c("line", {
                                attrs: {
                                  x1: "5",
                                  y1: "12",
                                  x2: "19",
                                  y2: "12",
                                },
                              }),
                            ]
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "span",
                        {
                          staticClass: "sr-only",
                          attrs: { id: "category-selector-button" },
                        },
                        [
                          _vm._v(
                            "Välj kategori " +
                              _vm._s(_vm.selectedCategory.title)
                          ),
                        ]
                      ),
                    ]
                  ),
                ]
              : [
                  _c(
                    "span",
                    {
                      staticClass: "pointer-events-none transition-opacity",
                      class: { "opacity-50": _vm.isCategoryFilterOpen },
                    },
                    [_vm._v("\n          Sök per kategori\n        ")]
                  ),
                ],
            _vm._v(" "),
            _c(
              "span",
              {
                staticClass:
                  "inline-block text-white pointer-events-none transition-transform",
                class: {
                  "opacity-50 transform-upside-down": _vm.isCategoryFilterOpen,
                },
              },
              [
                _c(
                  "svg",
                  {
                    staticClass: "fill-current",
                    attrs: {
                      width: "20px",
                      height: "20px",
                      viewBox: "0 0 768 768",
                      preserveAspectRatio: "none",
                    },
                  },
                  [
                    _c("path", {
                      attrs: {
                        d: "M355.457 16h57.086v624.466l163.103-161.86 40.775 40.805-232.42 232.588-232.421-232.588 40.775-42.165 163.102 163.219z",
                      },
                    }),
                  ]
                ),
              ]
            ),
          ],
          2
        ),
      ]),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.isCategoryFilterOpen,
              expression: "isCategoryFilterOpen",
            },
          ],
          staticClass:
            "absolute max-w-lg bg-blue-dark pt-8 pb-6 px-6 lg:px-12 z-10 pin-r pin-l mx-1",
          staticStyle: { top: "100%" },
          attrs: { id: "filterContainer" },
        },
        [
          _vm._l(_vm.categoryFilters, function (categoryGroup, key) {
            return [
              _vm.sectionType[0] !== "events" &&
              key === _vm.categoryFilters.length - 1
                ? _c("hr", {
                    key: "hr-" + key,
                    staticClass: "border-t border-solid border-grey-hr mt-4",
                  })
                : _vm._e(),
              _vm._v(" "),
              _c(
                "div",
                {
                  key: key,
                  staticClass: "flex flex-col md:flex-row",
                  class: {
                    "mt-w": key > 0,
                    "mt-6":
                      _vm.sectionType[0] !== "events" &&
                      key === _vm.categoryFilters.length - 1,
                    "mb-0":
                      _vm.sectionType[0] === "events" &&
                      key === _vm.categoryFilters.length - 1,
                  },
                },
                [
                  _c(
                    "div",
                    {
                      staticClass:
                        "text-white text-sm w-2/5 md:w-1/4 mt-4 md:mt-2 flex-no-shrink",
                    },
                    [
                      _c(
                        "span",
                        {
                          staticClass: "font-sans-bold",
                          class: {
                            "cursor-pointer": !!categoryGroup.groupCategory,
                            "border-b":
                              !!categoryGroup.groupCategory &&
                              _vm.selectedCategory.id ===
                                categoryGroup.groupCategory.id,
                          },
                          on: {
                            click: function ($event) {
                              !!categoryGroup.groupCategory &&
                                _vm.categorySelector(
                                  categoryGroup.groupCategory,
                                  undefined
                                )
                            },
                          },
                        },
                        [_vm._v(_vm._s(categoryGroup.groupTitle))]
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass: "w-full md:w-5/6 mt-4 md:mt-0 flex-no-grow",
                      attrs: { "data-toggle-container": "" },
                    },
                    [
                      _vm._l(categoryGroup.categories, function (cat, key) {
                        return _c(
                          "span",
                          {
                            key: key,
                            class: { hidden: key > 7 },
                            attrs: { "data-toggle-class": key > 7 && "hidden" },
                          },
                          [
                            _c(
                              "button",
                              {
                                staticClass:
                                  "leading-none btn-rnd btn-blue mb-3 mr-2 inline-flex flex-no-shrink px-3",
                                class: {
                                  "btn-white":
                                    _vm.selectedCategory.id === cat.id,
                                },
                                attrs: { id: cat.id },
                                on: {
                                  click: function ($event) {
                                    return _vm.categorySelector(
                                      cat,
                                      categoryGroup.groupCategory
                                    )
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(cat.title) +
                                    "\n              "
                                ),
                                _vm.selectedCategory.id === cat.id
                                  ? _c(
                                      "div",
                                      {
                                        staticClass: "inlin-block ml-1-5",
                                        staticStyle: {
                                          transform: "rotate(45deg)",
                                        },
                                      },
                                      [
                                        _c(
                                          "svg",
                                          {
                                            staticClass:
                                              "stroke-current block pointer-events-none",
                                            attrs: {
                                              width: "14px",
                                              height: "14px",
                                              viewBox: "0 0 24 24",
                                              fill: "none",
                                              "stroke-width": "3",
                                              "stroke-linecap": "square",
                                              "stroke-linejoin": "arcs",
                                              preserveAspectRatio: "none",
                                            },
                                          },
                                          [
                                            _c("line", {
                                              attrs: {
                                                x1: "12",
                                                y1: "5",
                                                x2: "12",
                                                y2: "19",
                                              },
                                            }),
                                            _vm._v(" "),
                                            _c("line", {
                                              attrs: {
                                                x1: "5",
                                                y1: "12",
                                                x2: "19",
                                                y2: "12",
                                              },
                                            }),
                                          ]
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                              ]
                            ),
                          ]
                        )
                      }),
                      _vm._v(" "),
                      categoryGroup.categories.length - 1 > 7
                        ? _c(
                            "button",
                            {
                              staticClass:
                                "btn-rnd btn-blue-dark mb-3 mr-2 flex-no-shrink px-3",
                              attrs: { "data-toggle": "" },
                            },
                            [
                              _c(
                                "span",
                                { attrs: { "data-toggle-text": "Visa färre" } },
                                [_vm._v("Visa fler")]
                              ),
                              _vm._v(" "),
                              _vm._m(0, true),
                            ]
                          )
                        : _vm._e(),
                    ],
                    2
                  ),
                ]
              ),
            ]
          }),
        ],
        2
      ),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("span", { staticClass: "pl-1 h-2 text-white inline-block" }, [
      _c("span", { attrs: { "data-toggle-class": "hidden" } }, [
        _vm._v("\n                +\n              "),
      ]),
      _vm._v(" "),
      _c(
        "span",
        { staticClass: "hidden", attrs: { "data-toggle-class": "hidden" } },
        [_vm._v("\n                -\n              ")]
      ),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }