<template>
  <transition name="fadeIn" mode="in-out">
    <div class="cardLoader mb-9 md:mb-6 px-3 w-full md:w-1/2" v-show="isLoading || !isReady" leave-stagger="500" :class="sectionType[0] === 'articles' ? 'xl:w-1/3' : 'xl:w-1/4'">
      <div class="flex flex-col h-full">
        <div class="mb-4 block relative object-fit-fallback aspect-ratio-box aspect-ratio-box--8-5">
          <div class="cardLoader__image absolute pin-t pin-l w-full h-full block object-cover"></div>
        </div>
        <span class="line-large heading-3 mb-none text-grey-dark hover:text-grey"></span>
        <div class="line-medium mt-2 flex items-center"></div>
      </div>
    </div>
  </transition>
</template>

<script>
import Vue from 'vue';

export default {
  name: 'CategoryLoadingIndicator',
  props: ['isLoading', 'isReady', 'sectionType']
};
</script>
