// assign HTMLCollection with parents of images with objectFit to variable
const toggleContainer = document.querySelectorAll('[data-toggle-container]')

// Loop through HTMLCollection
for (let i = 0; i < toggleContainer.length; i++) {
  const toggle = toggleContainer[i].querySelector('[data-toggle]')

  function toggleData(e) {
    if (e.type === 'keyup' && e.key !== ' ' || e.type === 'keydown' && e.key !== ' ')  {
      return;
    } else if (e.type === 'keydown' && e.key === ' ') {
      e.preventDefault();
      return;
    }
    e.preventDefault();
    let ariaExpanded = toggle.getAttribute("aria-expanded"); 
    if (ariaExpanded == "true") {
      ariaExpanded = "false"
    } else {
      ariaExpanded = "true"
    }
    toggle.setAttribute("aria-expanded", ariaExpanded);
    const toggleItems = toggleContainer[i].querySelectorAll(
      '[data-toggle-class]',
    )

    for (let j = 0; j < toggleItems.length; j++) {
      const toggleClass = toggleItems[j].getAttribute('data-toggle-class')

      if (toggleItems[j].classList) {
        toggleItems[j].classList.toggle(toggleClass)
      } else {
        var classes = toggleItems[j].className.split(' ')
        var existingIndex = classes.indexOf(toggleClass)

        if (existingIndex >= 0) classes.splice(existingIndex, 1)
        else classes.push(toggleClass)

        toggleItems[j].className = classes.join(' ')
      }
    }

    // if toggle text is found, toggle the text
    if (toggle.querySelector('[data-toggle-text]') !== null) {
      const toggleText = toggle.querySelector('[data-toggle-text]')
      const originalText = toggleText.innerHTML
      const newText = toggleText.getAttribute('data-toggle-text')

      toggleText.innerHTML = newText
      toggleText.setAttribute('data-toggle-text', originalText)
    }
  }

  if (toggle !== null) {
    toggle.onclick = toggleData;
    toggle.onkeyup = toggleData;
    toggle.onkeydown = toggleData;
  }
}
