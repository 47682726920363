<template>
  <div>
    <template v-if="sectionType[0] === 'events'">
      <div :style="{ minHeight: isLoading ? listMinHeight() : 'auto' }">
        <div class="mb-9 pb-3 border-b border-1 border-solid border-grey-light"></div>
        <div class="relative max-w-md xxl:max-w-full mx-auto">
          <div class="absolute w-full h-full" style="z-index: -1">
            <event-card-loading-indicator
              v-for="n in 3"
              :key="n"
              :is-loading="isLoading"
              :is-ready="isReady"
            ></event-card-loading-indicator>
          </div>
          <template v-if="items.length > 0">
            <ul class="list-reset">
              <event-search-item
                v-for="(item, key) in items"
                :key="key"
                :item="item"
                :is-loading="isLoading"
              ></event-search-item>
            </ul>
          </template>
          <transition name="fadeIn" mode="in-out">
            <template v-if="!isLoading && items.length === 0">
              <h4 class="heading-4 uppercase mx-auto py-8 text-center">Inga resultat hittades</h4>
            </template>
          </transition>
        </div>
      </div>
    </template>
    <template v-else>
      <div class="relative flex flex-wrap -mx-3 border-b border-1 border-solid border-grey-light" style="overflow: hidden" :style="{ minHeight: isLoading ? listMinHeight() : 'auto' }">
        <div class="absolute w-full h-full pin-x mx-auto flex flex-wrap content-start" style="z-index: -1">
          <card-loading-indicator
            v-for="n in 8"
            :key="n"
            :is-loading="isLoading"
            :is-ready="isReady"
            :section-type="sectionType"
          ></card-loading-indicator>
        </div>
        <template v-if="items.length > 0">
          <category-search-item
            v-for="(item, key) in items"
            :key="key"
            :item="item"
            :is-loading="isLoading"
            :section-type="sectionType"
          ></category-search-item>
        </template>
        <transition name="fadeIn" mode="in-out">
          <template v-if="!isLoading && items.length === 0">
            <h4 class="heading-4 uppercase mx-auto py-8 text-center">Inga resultat hittades</h4>
          </template>
        </transition>
      </div>
    </template>
  </div>
</template>
<script>
import Vue from 'vue';
import CategorySearchItem from './CategorySearchItem'
import EventSearchItem from './EventSearchItem'
import CardLoadingIndicator from './CardLoadingIndicator'
import EventCardLoadingIndicator from './EventCardLoadingIndicator';

export default {
  name: 'CategorySearchList',
  props: {
    sectionType: {
      type: Array,
      default: []
    },
    items: {
      type: Array,
      default: [],
    },
    elementsPerPage: {
      type: Number,
      default: 0
    },
    isLoading: {
      type: Boolean,
      default: false
    },
    isReady: {
      type: Boolean,
      default: false
    }
  },
  components: {
    CategorySearchItem,
    CardLoadingIndicator,
    EventSearchItem,
    EventCardLoadingIndicator
  },
  methods: {
    listMinHeight: function() {
      let rowHeight = this.sectionType[0] === 'events' ? 280 : 240;
      let rowAmount = this.sectionType[0] === 'events' ? 3 : 8/4;
      if ( window.innerWidth < 480 ) {
        rowHeight = this.sectionType[0] === 'events' ? 280 : 340;
        rowAmount = this.sectionType[0] === 'events' ? 3 : 4;
      } else if ( window.innerWidth < 780 ) {
        rowHeight = this.sectionType[0] === 'events' ? 280 : 240;
        rowAmount = this.sectionType[0] === 'events' ? 3 : 8/2;
      }
      return rowAmount * rowHeight + 'px';
    }
  }
};
</script>
