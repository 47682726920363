import Vue from 'vue'
import axios from 'axios'
import _ from 'lodash'
import { configureApi, apiToken, apiUrl, getQueryParam, executeQuery } from './searchCommon';
import CategorySearchMeta from '../../vue/CategorySearchMeta'
import SearchPageList from '../../vue/SearchPageList'
import Pagination from '../../vue/Pagination'

// Site search application
function getcategorySearchLandingData() {
  return {
    sectionType: '',
    searchApi: axios.create(configureApi(apiUrl, apiToken)),
    searchQuery: '',
    searchResults: [],
    searchSorting: 'postdate desc',
    pagination: {
      totalCount: 0,
      pages: [],
      hasNext: false,
      hasPrev: false,
      nextPageUrl: '',
      prevPageUrl: '',
      elementsPerPage: 12,
      currentPage: 1
    },
    isLoading: true,
    isReady: false,
  };
}

var filterChangeTimer;

var generalSearch = function () {

  // What to search for
  const searchEntries = ['articles_article_Entry', 'articles_podcast_Entry', 'articles_video_Entry', 'events_event_Entry', 'recipes_recipe_Entry', 'pages_page_Entry', 'products_product_Entry', 'tips_tip_Entry'];
  let searchQueries = ''
  _.each(searchEntries, (entryType) => {
    searchQueries = searchQueries +
      `
      ... on ${entryType} {
        typeId
        id
        title
        url${entryType !== 'tips_tip_Entry' ? `
          image {
            url @transform (width: 300)
          }
        ` : ''}
        postDate${entryType === 'events_event_Entry' ? `
          startDate
          location
          ingress
          places {
            title
          }
          speaker {
            title
          }
        ` : ''}${entryType !== 'events_event_Entry' && entryType !== 'pages_page_Entry' ? `
        categories {
          title
        }
      ` : ''}
      }`
  });

  // The query to search for entries in Craft
  const searchQuery =
    `
query searchQuery($needle: String, $limit: Int, $offset: Int, $orderBy: String, $site: [String]) {
  entries(limit: $limit, search: $needle, orderBy: $orderBy, site: $site, offset: $offset) {
    ${searchQueries}
  }
  entryCount(limit: $limit search: $needle offset: $offset)
}
`;


  new Vue({
    el: document.getElementById('searchPage'),
    delimiters: ['<%', '%>'],
    // Here we can register any values or collections that hold data
    data: getcategorySearchLandingData(),
    components: {
      SearchPageList,
      CategorySearchMeta,
      Pagination,
    },
    beforeCreate: function () {
    },
    created: function () {
      const searchParam = getQueryParam('q');
      const categoryParam = getQueryParam('cat');
      const categoryIdParam = getQueryParam('cat-id');
      const pageParam = getQueryParam('page');

      if (!!searchParam) {
        this.searchQuery = searchParam;
      }
      if (!!categoryParam) {
        this.searchCategory.slug = categoryParam;
      }
      if (!!categoryIdParam) {
        this.searchCategory.id = categoryIdParam;
      }
      if (!!pageParam) {
        this.pagination.currentPage = parseInt(pageParam);
      }
    },
    mounted: function () {
      this.performSearch();
    },
    updated: function () {
    },
    destroyed: function () {
    },
    watch: {
      'pagination.currentPage': function (val) {
        this.scrollup();
        this.pagination.offset = this.pagination.elementsPerPage * (val - 1);
        this.performSearch();
      },
      searchQuery: function (val, oldVal) {
        if (!!oldVal && val !== oldVal) {
          this.pagination = _.get(getcategorySearchLandingData(), 'pagination');
        }
        this.performSearch();
      },
      searchSorting: function () {
        this.scrollup();
        this.performSearch();
      },
    },
    filters: {
    },
    computed: {
    },
    methods: {
      performSearch() {
        var self = this;

        if (self.searchQuery === '') {
          self.searchResults = [];
          self.isSearchDropdownOpen = false;
          return true;
        }

        self.isLoading = true;
        self.setHistory();
        const searchTitle = !!self.searchQuery ? `title:*${self.searchQuery}*` : '';
        const searchCategory = !!self.searchQuery && `categories:"${self.searchQuery}" OR categories::"${self.searchQuery}"`;
        const searchTag = !!self.searchTag && `tags:${self.searchQuery} OR tags::${self.searchQuery}`;

        const searchString = _.compact([searchTitle, searchCategory, searchTag]).join(' OR ');

        // Set the variables we will pass in to our query
        const variables = {
          needle: searchString,
          limit: self.pagination.elementsPerPage || 24,
          offset: self.pagination.offset || 0,
          orderBy: self.searchSorting,
        };
        // Execute the query
        clearTimeout(filterChangeTimer);

        // Execute the query
        filterChangeTimer = setTimeout(function () {
          executeQuery(self.searchApi, searchQuery, variables, (data) => {
            const dataPath = data.data;
            self.searchResults = dataPath.entries;
            self.pagination.totalCount = dataPath.entryCount;
            self.pagination.totalPages = Math.ceil(Number(dataPath.entryCount) / (self.pagination.elementsPerPage || 24));;

            if (!self.isReady) {
              self.isReady = true;
            }
            self.isLoading = false;
          });
        }, 500);
      },
      setHistory: function () {
        var self = this;
        var paramString = '';
        if (!!self.searchQuery) {
          paramString += '?q=' + self.searchQuery;
        }
        if (!!_.get(self.searchCategory, 'slug')) {
          paramString += !!paramString ? ('&cat=' + self.searchCategory.slug) : (paramString += '?cat=' + self.searchCategory.slug);
          paramString += !!paramString ? ('&cat-id=' + self.searchCategory.id) : (paramString += '?cat-id=' + self.searchCategory.id);
        }
        if (!!self.pagination.currentPage) {
          paramString += !!paramString ? ('&page=' + self.pagination.currentPage) : (paramString += '?page=' + self.pagination.currentPage);
        }
        if (window.history && window.history.replaceState) {
          var pageUrl =
            location.protocol + '//' + location.host + location.pathname;
          var url = pageUrl + paramString;
          history.replaceState(null, null, url);
        }
      },
      scrollup: function () {
        var top = 0;

        setTimeout(function () {
          window.scrollTo(top, 0);
        }, 100);
        return false;
      },
    },
  });
};

!!document.getElementById('searchPage') && generalSearch();
