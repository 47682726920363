var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { style: { minHeight: _vm.isLoading ? _vm.listMinHeight() : "auto" } },
    [
      _c("div", {
        staticClass:
          "mb-4 pb-3 border-b border-1 border-solid border-grey-light",
      }),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "relative mx-auto" },
        [
          _c(
            "div",
            {
              staticClass: "absolute w-full h-full",
              staticStyle: { "z-index": "-1" },
            },
            _vm._l(3, function (n) {
              return _c("search-page-loading-indicator", {
                key: n,
                attrs: { "is-loading": _vm.isLoading, "is-ready": _vm.isReady },
              })
            }),
            1
          ),
          _vm._v(" "),
          _vm.items.length > 0
            ? [
                _c(
                  "ul",
                  { staticClass: "list-reset" },
                  _vm._l(_vm.items, function (item, key) {
                    return _c("search-page-item", {
                      key: key,
                      attrs: { item: item, "is-loading": _vm.isLoading },
                    })
                  }),
                  1
                ),
              ]
            : _vm._e(),
          _vm._v(" "),
          _c(
            "transition",
            { attrs: { name: "fadeIn", mode: "in-out" } },
            [
              !_vm.isLoading && _vm.items.length === 0
                ? [
                    _c(
                      "h4",
                      {
                        staticClass:
                          "heading-4 uppercase mx-auto py-8 text-center",
                      },
                      [_vm._v("Inga resultat hittades")]
                    ),
                  ]
                : _vm._e(),
            ],
            2
          ),
        ],
        2
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }