var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass:
        "-mx-3 flex flex-wrap md:justify-between text-center md:text-left",
      class:
        _vm.sectionType[0] === "events" ||
        _vm.sectionType[0] === "searchLanding"
          ? "mb-0"
          : "mb-3",
    },
    [
      _c(
        "div",
        {
          staticClass: "px-3 w-1/2 text-left",
          class:
            _vm.sectionType[0] === "events" ||
            _vm.sectionType[0] === "searchLanding"
              ? "mb-0"
              : "mb-3 md:mb-0",
        },
        [
          _vm.itemsAmount > 0
            ? _c("div", [
                _c(
                  "span",
                  {
                    staticClass:
                      "pr-1 small-text-bold text-blue-accessible-text",
                  },
                  [
                    _vm._v(
                      _vm._s(_vm.itemOffset + 1) +
                        " – " +
                        _vm._s(_vm.itemsAmount)
                    ),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "span",
                  { staticClass: "small-text text-grey-accessible-text" },
                  [_vm._v("(av " + _vm._s(_vm.totalItems) + ")")]
                ),
              ])
            : _c("div", [
                _c(
                  "span",
                  {
                    staticClass:
                      "pr-1 small-text-bold text-blue-accessible-text",
                  },
                  [_vm._v("0")]
                ),
                _vm._v(" "),
                _c(
                  "span",
                  { staticClass: "small-text text-grey-accessible-text" },
                  [_vm._v("(av 0)")]
                ),
              ]),
        ]
      ),
      _vm._v(" "),
      _c("search-sorting", {
        attrs: { sorting: _vm.searchSorting, options: _vm.sortingOptions },
        on: {
          "update:sorting": function ($event) {
            _vm.searchSorting = $event
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }